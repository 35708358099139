<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="templates"
			:model="model"
			@saved="$emit('saved')"
			path="templates"
		>
			<template v-slot:name="{ data }" v-if="!id">
				<v-text-field v-model="data.name" label="The content id" hint="No spaces or special characters"></v-text-field>
			</template>
			<template v-slot:html="{ data }">
				<mw-editor v-model="data.html"></mw-editor>
			</template>
			<!-- <template v-slot:fields="{ data }" v-if="id">
				<v-card color="lightest" flat class="mt-5">
					<v-card-text>

						<p class="large-light">
							Field Definitions
						</p>
						
						<div v-for="(field, f) in data.fields" :key="f">
						<v-text-field v-model="data.fields[f]" :label="f" hint="A description of this template field for reference"></v-text-field>
						</div>
					</v-card-text>
				</v-card>
			</template> -->
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Template from "@/models/Template";
import MwEdit from "@c/ui/MwEdit";
import MwEditor from "@c/ui/MwEditor";


export default {
	name: "EditTemplate",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Template;
		},
	},
	components: {
		MwEdit,
        MwEditor
    },
};
//
</script>
"
