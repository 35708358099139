export default {
    name: {
        value: "name", 
        display: true,
        label: "Name / ID"
    },
    subject: {
        value: "subject", 
        display: true,
        component: "v-text-field",
        label: "Subject"
    },
    type: {
        value: "type", 
        display: true,
        component: "v-select",
        label: "Type",
        items: ["email", "message"]
    },
    html: {
        value: "html", 
        display: true, 
        label: "Html"
    },
    // fields: {
    //     value: "fields", 
    //     display: true, 
    //     label: "Fields"
    // },
    partial: {
        value: "partial", 
        display: true, 
        default: false,
        label:" Partial template" 
    }
}